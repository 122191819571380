import * as React from 'react';
import MainLayout from '../components/layout/MainLayout';
import HeroSection from '../components/sections/HeroSection';
import TechSection from '../components/sections/TechSection';

const IndexPage = () => {
  return (
    <MainLayout>
      <div>
        <HeroSection />
        <TechSection />
      </div>
    </MainLayout>
  );
};

export default IndexPage;
